<template>
  <div>
    <v-simple-table class="ma-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Denominação</th>
          <th class="text-left">Dados</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td >Nome Fantasia</td>
          <td>{{ company.fantasyName }}</td>
        </tr>
        <tr >
          <td>Nome Empresarial</td>
          <td>{{ company.tradingName }}</td>
        </tr>
        <tr >
          <td>Data de Abertura</td>
          <td>{{ company.oppenAt }}</td>
        </tr>
        <tr >
          <td>Descrição das Atividades</td>
          <td>{{ company.activityDescription }}</td>
        </tr>
        <tr >
          <td>CNPJ</td>
          <td>
            {{ company.cnpj | toCNPJ }}
            <k-download
            class="ml-3"
            v-show="company.idCnpjCard !== null"
            :id="company.idCnpjCard"
            @click="snackbar = true"
          ></k-download>
          </td>
        </tr>
        <tr>
          <td>Senha para emissão de Notas Fiscais</td>
          <td>
            {{ !!showPassword ? company.senhaWebPrefeitura : '**********' }}
            <v-btn class="ml-3" text icon @click="showPassword = !showPassword">
              <v-icon>{{ showPassword ? 'visibility' : 'visibility_off' }}</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td>CNAEs</td>
          <td >
              <v-row no-gutters class="mt-2" v-if="!!company.cnaePrimario">
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4' ">Primário: <span>{{ company.cnaePrimario.cnaeCode }}</span> </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4' ">Cód. Municipal: <span>{{ company.citiesServiceCodes }}</span> </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4' ">Anexo: <span>{{ company.cnaePrimario.anexo }}</span> </v-col>
              </v-row>
              <v-row 
                no-gutters class="mt-2" 
                v-for="(cnaeSecundario, index) in company.cnaeSecundario"
                :key="index"
                >
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4' ">Secundário: <span>{{ cnaeSecundario.cnaeCode }}</span> </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4' ">Cód. Municipal: <span>{{cnaeSecundario.subCnae.citiesServiceCodes}}</span> </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '4' ">Anexo: <span>{{ cnaeSecundario.anexo }}</span> </v-col>
              </v-row>
          </td>
        </tr>
        <tr>
          <td>Cód. Simples Nacional</td>
          <td> {{ company.simplesNacionalCode | toCodSimpNacional }}</td>
        </tr>
        <tr>
          <td>NIRE</td>
          <td>{{ company.nire | toNIRE }}</td>
        </tr>
        <tr>
          <td>Inscrição Municipal</td>
          <td>{{ company.inscricaoMunicipal | toInscMunicipal }}</td>
        </tr>
        <tr>
          <td>Inscrição Estadual</td>
          <td>{{ company.inscricaoEstadual | toInscEstadual }}</td>
        </tr>
        <tr>
          <td>Nº do IPTU</td>
          <td>{{ company.iptuNumber | toIPTU }}</td>
        </tr>
        <tr>
          <td>CEP</td>
          <td>{{ company.cep | toCEP }}</td>
        </tr>
        <tr>
          <td>Endereço da Empresa</td>
          <td>{{ company.address }}</td>
        </tr>
        <tr>
          <td>Número</td>
          <td>{{ company.addressNumber }}</td>
        </tr>
        <tr>
          <td>Complemento</td>
          <td>{{ company.addressComplement }}</td>
        </tr>
        <tr>
          <td>Bairro</td>
          <td>{{ company.district }}</td>
        </tr>
        <tr>
          <td>Cidade</td>
          <td>{{ company.city }}</td>
        </tr>
        <tr>
          <td>Estado</td>
          <td>{{ company.state }}</td>
        </tr>
        <tr>
          <td>Telefone fixo</td>
          <td>{{ company.phone | toTEL }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-snackbar
      :timeout="null"
      :top="true"
      :bottom="false"
      :right="false"
      :left="false"
      :multi-line="false"
      :vertical="true"
      v-model="snackbar"
    >
      Você fez o download do arquivo solicitado com sucesso
      <v-btn text color="grey" @click.native="snackbar = false">Fechar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import kDownload from '@src/components/widget/Download.vue'

export default {
  components: {
    kDownload,
  },
  props: {
    company: {
      type: Object,
    },
  },
  data: () => ({
    snackbar: false,
    uri: process.env.VUE_APP_API_URI,
    showPassword: false,
    loading: false,
  }),
  mounted() {},
}
</script>
<style scoped>
.field .name {
  color: gray;
}
a.icon {
  text-decoration: none;
}

</style>
