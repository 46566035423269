<template>
  <v-container fluid>
    <v-row >
      <v-subheader>Minha Empresa > Dados da Empresa</v-subheader>
      <v-card width="100%">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>
        <k-ask-change-btn ></k-ask-change-btn>
        <company-data v-if="loading" :company="company"></company-data>
        <v-row class="py-2" justify="center" v-if="this.company.hasSocialContract" >
          <v-col cols="8">
            <v-btn
              color="grey darken-1"
              class="white--text"
              @click="navigateTo('/contrato-social')"
            >
              <v-icon left dark>cloud_download</v-icon>
              Contrato Social
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import companyData from '@src/components/company/company-data.vue';
import kAskChangeBtn from '@src/components/widget/AskChangeBtn.vue';


export default {
  name: 'Company',
  components: {
    companyData,
    kAskChangeBtn,
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },
  data: () => ({
    snackbar: false,
    headerTitle: 'Dados da Empresa',
    headerDescription: `Aqui estão os dados que você cadastrou em nosso sistema. Fique atento porque eles
    precisam estar de acordo com o contrato social da sua empresa.
    Caso precise alterar alguma coisa, vá em Serviços Adicionais.`,
    loading: false,
  }),
  methods: {
    ...mapActions({
      getCompany: 'company/getCompany',
    }),
    navigateTo(url) {
      /* eslint-disable no-alert */
      if (url === 'teste') alert('Tela não criada')
      this.$router.push({ path: url })
    },
  },
  mounted() {
    this.getCompany().then(() => {
      this.loading = true
    })
  },
}
</script>
<style scoped></style>
